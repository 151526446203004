var fb_theme_slideIndex = 1;

function fb_theme_plusSlides(n) {
    fb_theme_showSlides(fb_theme_slideIndex += n);
}

function fb_theme_currentSlide(n) {
    fb_theme_showSlides(fb_theme_slideIndex = n);
}

function fb_theme_showSlides(n) {
    var i;
    var fb_theme_slides = document.getElementsByClassName("fb-theme-mySlides");
    var dots = document.getElementsByClassName("fb-theme-dot");

    if (n > fb_theme_slides.length) {
        fb_theme_slideIndex = 1
    }

    if (n < 1) {
        fb_theme_slideIndex = fb_theme_slides.length
    }

    for (i = 0; i < fb_theme_slides.length; i++) {
        fb_theme_slides[i].style.display = "none";  
    }

    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" fb-theme-active", "");
    }

    fb_theme_slides[fb_theme_slideIndex - 1].style.display = "block";  
    dots[fb_theme_slideIndex-1].className += " fb-theme-active";
}