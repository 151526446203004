// ================================
// DATEN
// ================================ 
var songs = [
    "http://frissbee.de/audio/SANSOL - Unterwegs mit dir.mp3",
    "http://frissbee.de/audio/SANSOL - Anyway.mp3",
    "http://frissbee.de/audio/SANSOL - Es ist alles okay.mp3",
    "http://frissbee.de/audio/SANSOL - like a gun.mp3"
];

var songName = [
    "Unterwegs mit dir",
    "Anyway",
    "Es ist alles okay",
    "Like A Gun"
];

var songImg = [
    "http://frissbee.de/images/work/FrissBee_Player/unterwegs-mit-dir.jpg",
    "http://frissbee.de/images/work/FrissBee_Player/Anyway_Cover_Playliste.jpg",
    "http://frissbee.de/images/work/FrissBee_Player/alles-okay_playliste_2.jpg",
    "http://frissbee.de/images/work/FrissBee_Player/like-a-gun.jpg"
];

var bandName = [
    "SANSOL",
    "SANSOL",
    "SANSOL",
    "SANSOL"
];

// ================================
// ALLGEMEINE VARIABLEN
// ================================
var pathAudio = "audio/";
var backColorSongActive = "background: #a12121";
var fontsize = "font-size:20px;"
var colorOn = "color: #d1a8a8;";
var colorOff = "color: #a12121;";
var classPlay = "fa fa-play playpause";
var classPause = "fa fa-pause playpause";
var displayBlock = "display: block;";
var displayNone = "display: none;";
var song = new Audio();
var currentSong = 0;

// ================================
// FUNCTIONS
// ================================
var intervalSlider;
var intervalTime;

function set_Intervals(){
    intervalSlider = setInterval(updateSongSlider, 1000);
    intervalTime = setInterval(updateSongTime, 1000);
}

function clear_Intervals(){
    clearInterval(intervalSlider);
    clearInterval(intervalTime);
}

// Zeigt Band Name, Song Titel und Song Image an
function setTitelAndImage(currentSong){
    document.getElementById('songImage').src = songImg[currentSong];
    document.getElementById('bandName').textContent =  bandName[currentSong];
    document.getElementById('songTitle').textContent = songName[currentSong];
}

// Lädt den Song und die Playliste
function loadSong(){
    if(songs != ""){
        song.src = songs[currentSong];
        setTitelAndImage(currentSong);
    
        var x = "";
        for(var i = 0; i < songs.length; i++){
            x = x + "<li id='songID" + i + "' onclick='playSongFromList(" + i + ")'><img src='" + songImg[i] + "' /><span >" + (i + 1) + ". " + songName[i] + "</span></li>" ;
        }
        document.getElementById('playlist').innerHTML = x;
    
        song.volume = document.getElementById('volumeSlider').value;
        setTimeout(showDuration, 10);
    }
}

// Spiel den Song ab, auf den man in der Play Liste cklickt
function playSongFromList(current){
    song.src = songs[current];
    setTitelAndImage(current);
    document.getElementById('play_pause').setAttribute("class", classPause);
    this.currentSong = current;
    loadSong();
    set_Intervals();
    playSong();
}

// Zählt die Laufzeit des Songs auf 00:00 runter während des Abspielens
function updateSongTime(){
    var d = Math.round(song.duration);
    var c = Math.floor(song.currentTime);

    document.getElementById('duration').textContent = "-" + convertTime(d - c);
}

// Updatet den Song Slider
// Spielt den nächsten Song ab nach Beendigung      
function updateSongSlider(){
    var c = Math.round(song.currentTime);
    var d = Math.round(song.duration);
    document.getElementById('songSlider').max = d;
    document.getElementById('songSlider').value = c;
    document.getElementById('currentTime').textContent = convertTime(c);

    if(!document.getElementById('fbp-repeat-id').checked){
        if(song.ended && currentSong < songs.length -1 ){
            next();
        }
    }else{
        if(song.ended){
            next();
        }
    }
}

// Konvertiert die Laufzeit des Songs in 00:00
function convertTime(secs){
    var min = Math.floor(secs/60);
    var sec = secs % 60;
    min = (min < 10) ? "0" + min : min;
    sec = (sec < 10) ? "0" + sec : sec;
    return (min + ":" + sec);
}

// Setzt die Laufzeit des Songs und zeigt sie an
function showDuration(){
    var d = Math.floor(song.duration);
    document.getElementById('songSlider').setAttribute("max", d);

    if(convertTime(d) == "NaN:NaN"){
        document.getElementById('duration').textContent = "00:00";
    }else{
        document.getElementById('duration').textContent = convertTime(d);
    }
}
 
// Spielt den Song ab
// Setzt den Song, der gerade abgespielt wird die "active" Hintergrundfarbe
function playSong(){
    song.play();
    document.getElementById('songID' + currentSong).setAttribute("style", backColorSongActive);
}

// Schaltet zwischen Play und Pause um
function playOrPauseSong(){
    if(song.paused){
        set_Intervals();
        playSong();
        document.getElementById('play_pause').setAttribute("class", classPause);
    }else{
        clear_Intervals();
        song.pause();
        document.getElementById('play_pause').setAttribute("class", classPlay);
    }
}

// Der Forward Button berücksichtigt auch die Random Einstellung
function next(){
var x = document.getElementById('randomId').checked;
if(x){
    currentSong = Math.floor((Math.random() * songs.length - 1) + 1);
    document.getElementById('play_pause').setAttribute("class", classPause);
    loadSong();
    playSong();
}else{
    currentSong++;
    currentSong = (currentSong > songs.length -1) ? currentSong = 0 : currentSong;
    document.getElementById('play_pause').setAttribute("class", classPause);
    loadSong();
    playSong();
    
    }
}

// Der Forward Button berücksichtigt auch die Random Einstellung
function previous(){
var x = document.getElementById('randomId').checked;
    if(x){
        currentSong = Math.floor((Math.random() * songs.length - 1) + 1);
        document.getElementById('play_pause').setAttribute("class", classPause);
        loadSong();
        playSong();
    }else{
        currentSong--;
        currentSong = (currentSong < 0) ? songs.length - 1 : currentSong;
        document.getElementById('play_pause').setAttribute("class", classPause);
        loadSong();
        playSong();
    }
}

 // Mit der Mouse kann im Song Slider vorgesprungen werden
function seekSong(){
    song.currentTime = document.getElementById('songSlider').value;
}

// Volume wird von dem Volume Slider übernommen
function adjustVolume(){
    song.volume = document.getElementById('volumeSlider').value;
    // Übernimmt Valume Wert in "Echtzeit" -> aber erst ab dem zweiten mal
    document.getElementById('volumeSlider').oninput = function() {
    song.volume = this.value;
    }
}

// Wechelt beim clicken auf den Random Icon die Farbe zwischen aktiv und deaktiv
function randomOnOff(){
    var radomChecked = document.getElementById('randomId').checked;
    if(radomChecked){
        document.getElementById('randomId').checked = false;
        document.getElementById('radom-icon-id').setAttribute("style", fontsize + " " + colorOff);
    }else{
        document.getElementById('randomId').checked = true;
        document.getElementById('radom-icon-id').setAttribute("style", fontsize + " " + colorOn);
    }
}

// Wechselt beim clicken auf den Repeat Icon die Farbe zwischen aktiv und deaktiv
function repeatOnOff(){
    var repeatChecked = document.getElementById('fbp-repeat-id').checked;
    if(repeatChecked){
        document.getElementById('fbp-repeat-id').checked = false;
        document.getElementById('fbp-repeat-icon-id').setAttribute("style", fontsize + " " + colorOff);
    }else{
        document.getElementById('fbp-repeat-id').checked = true;
        document.getElementById('fbp-repeat-icon-id').setAttribute("style", fontsize + " " + colorOn);
    }
}

// Schaltet die Playliste ein und aus
function listOnOff(){
    var x = document.getElementById('play_list_Row').getAttribute("style");
    if(x == "display: block;"){
        document.getElementById('play_list_Row').setAttribute("style", displayNone);
        document.getElementById('listonoffid').setAttribute("style", fontsize + " " + colorOff);
        document.getElementById('checkList').checked = false;
    }else{
        document.getElementById('play_list_Row').setAttribute("style", displayBlock);
        document.getElementById('listonoffid').setAttribute("style", fontsize + " " + colorOn);
        document.getElementById('checkList').checked = true;
    }
}
