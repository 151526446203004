// Button: Löschen (in Liste)
function deleteById(clicked_id){
    if (confirm("Zeile unwiderruflich löschen? ID: " + clicked_id)) {
        document.getElementById("fb-id-input").value = clicked_id;
        $("#fb-submit-loeschen-ja-id").click();
    }
}

// Button: Bearbeiten 
function editById(clicked_id, vorname, nachname, alter, gender, email){
    document.getElementById("fb-id-input").value = clicked_id;
    document.getElementById("fb-field-id").value = clicked_id;
    document.getElementById("fb-vorname-id").value = vorname;
    document.getElementById("fb-nachname-id").value = nachname;
    document.getElementById("fb-alter-id").value = alter;
    document.getElementById("fb-gender-id").value = gender;
    document.getElementById("fb-eMail-id").value = email;

    // Go to the Top of the Page
    $('html, body').animate({ scrollTop: 0 }, 'fast');
}

// Suche.. Feld
$(document).ready(function(){
    $("#fb-search-Input-1").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#fb-dbTable-1 tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});

// Button: Löschen By ID
function fb_show_ja_nein_btns(){
  var id_input = document.getElementById('fb-id-input');

  if(id_input.value == ""){
    document.getElementById('fb-ask-for-delete-id').innerHTML = "Bitte eine ID eingeben."
    document.getElementById('fb-ask-for-delete-id').style.display = 'block';
  }else{
    document.getElementById('fb-ask-for-delete-id').innerHTML = "Löschen?"
    document.getElementById('fb-ask-for-delete-id').style.display = 'block';
    document.getElementById('fb-submit-loeschen-ja-id').style.display = 'inline';
    document.getElementById('fb-submit-loeschen-nein-id').style.display = 'inline';
  }
}

// Button: Löschen By ID
function fb_hide_ja_nein_btns(){
    document.getElementById('fb-ask-for-delete-id').style.display = 'none';
    document.getElementById('fb-submit-loeschen-ja-id').style.display = 'none';
    document.getElementById('fb-submit-loeschen-nein-id').style.display = 'none';
}

// Blendet aus
$(document).ready(function(){/* 
  var ele = $("#fb-display-eingabe-id");
  setTimeout(function() { ele.hide("slow"); }, 3000); */

  $("#fb-display-eingabe-id").fadeOut(3000);
});

// Sortiert die Personentabelle nach dem Alphabet
function sortTableByAbc(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("fb-person-table-id");
  switching = true;
  dir = "asc";
  while (switching) {
    switching = false;
    rows = table.rows;
    for (i = 1; i < (rows.length - 1); i++) {
      shouldSwitch = false;
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      if (dir == "asc") {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      switchcount ++;
    } else {
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}

// Sortiert die Personentabelle nach Zahlen
function sortTableByNumber(n) {
  var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  table = document.getElementById("fb-person-table-id");
  switching = true;
  dir = "asc";
  while (switching) {
    switching = false;
    rows = table.rows;
    for (i = 1; i < (rows.length - 1); i++) {
      shouldSwitch = false;
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      if (dir == "asc") {
        if (Number(x.innerHTML) > Number(y.innerHTML)) {
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (Number(x.innerHTML) < Number(y.innerHTML)) {
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      switchcount ++;
    } else {
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
}

