function fb_openLightbox() {
    document.getElementById("fb-lightbox-id").style.display = "block";
    var fixed_class = document.getElementsByClassName('navbar');
    $(fixed_class).removeClass("fixed-top");
  }
  
  function fb_closeLightbox() {
    document.getElementById("fb-lightbox-id").style.display = "none";
  }

  function fb_openLightbox_multi(n) {
    document.getElementById("fb-lightbox-id_" + n).style.display = "block";
    var fixed_class = document.getElementsByClassName('navbar');
    $(fixed_class).removeClass("fixed-top");
  }
  
  function fb_closeLightbox_multi(n) {
    document.getElementById("fb-lightbox-id_" + n).style.display = "none";
  }