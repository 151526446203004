// ====================================
// Buy - Change Infos
// ====================================
function fb_gb_version_select(){
    var fb_select_gb_1 = document.getElementById('fb-select-gb-1');

    if(fb_select_gb_1.value == "Vollversion"){
        document.getElementById('fb-gb-icon-select-id').innerHTML = "<i class='fa fa-info-circle text-warning' style='font-size:20px'></i>";
        document.getElementById('fb-gb-info-select-id').innerHTML = "Bitte einloggen, um den Kauf auszuführen.";
        document.getElementById('fb-select-price-id').innerHTML = "<p style='font-size: 28px'><b>199,99 €</b></p>";
    }
    
    if(fb_select_gb_1.value == "Update"){
        document.getElementById('fb-gb-icon-select-id').innerHTML = "<i class='fa fa-warning' style='font-size:20px; color:red'></i>";
        document.getElementById('fb-gb-info-select-id').innerHTML = "Für ein Update ist eine lizensierte <b>guitar</b>BOOSTER Version 1.x <u>Vollversion</u> Vorraussetzung.<br><br>" + 
        "Bitte einloggen, um den Kauf auszuführen.";
        document.getElementById('fb-select-price-id').innerHTML = "<p style='font-size: 28px'><b>99,99 €</b></p>";
    }
    
    if(fb_select_gb_1.value == "Upgrade"){
        document.getElementById('fb-gb-icon-select-id').innerHTML = "<i class='fa fa-question-circle' style='font-size: 20px; color: #4f5ed1'></i>";
        document.getElementById('fb-gb-info-select-id').innerHTML = "Für ein Upgrade ist eine <b>guitar</b>BOOSTER <u>Element</u> Version Vorraussetzung.<br><br>" + 
        "Bitte einloggen, um den Kauf auszuführen.";
        document.getElementById('fb-select-price-id').innerHTML = "<p style='font-size: 28px'><b>149,99 €</b></p>";
    }
}

function fb_gb_product_select(){
    var fb_select_gb_2 = document.getElementById('fb-select-gb-2');

    if(fb_select_gb_2.value == "Download"){
        document.getElementById('fb-gb-icon-getting-id').innerHTML = "<i class='fa fa-dot-circle-o' style='font-size: 16px; color: green'></i>";
        document.getElementById('fb-select-getting-id').innerHTML = "Dowload-Version:<br>sofort verfügbar";
        document.getElementById("fb-gb-warenkorb-btn").disabled = false;
    }

    if(fb_select_gb_2.value == "Produktbox"){
        document.getElementById('fb-gb-icon-getting-id').innerHTML = "<i class='fa fa-dot-circle-o' style='font-size: 16px; color: red'></i>";
        document.getElementById('fb-select-getting-id').innerHTML = "<span style='color: red'><b>Derzeit nicht verfügbar!</b></span><br><br>" + 
        "<b>Kostenloser Versand</b><br>(Deutschland)";
        document.getElementById("fb-gb-warenkorb-btn").disabled = true;
    }
}

// ====================================
// Buy - open Warenkorb Window
// ====================================
function fb_gb_add_to_card(){
    var fb_select_gb_1 = document.getElementById('fb-select-gb-1');
    var fb_select_gb_2 = document.getElementById('fb-select-gb-2');
    var fb_select_gb_3 = document.getElementById('fb-select-gb-3');
    var fb_select_price = document.getElementById('fb-select-price-id').innerHTML;

    var myWindow = window.open("", "MsgWindow", "width=450,height=500");
    myWindow.document.write("<div style='font-family: Verdana, Geneva, Tahoma, sans-serif'>" + 
        "<h3>Deine Bestellung</h3>" + 
        "<p><b>Produkt:</b> guitarBOOSTER 2.0</p>" + 
        "<p><b>Version:</b> " + fb_select_gb_1.value + "</p>" + 
        "<p><b>Produktart:</b> " + fb_select_gb_2.value + "</p>" + 
        "<p><b>Menge:</b> " + fb_select_gb_3.value + "</p>" +
        "<p><b>Einzelpreis:</b> " + fb_select_price + "</p>" + 
        "</div>");
}

// ====================================
// Sticky SubMenu - Underline
// ====================================
var underline_sub = "#2d68b5";

$(".fb-xxx-1").hover(function(){
    $('.fb-gb-sticky-nav-1').css("background-color", underline_sub);
    }, function(){
    $('.fb-gb-sticky-nav-1').css("background-color", "");
});

$(".fb-xxx-2").hover(function(){
    $('.fb-gb-sticky-nav-2').css("background-color", underline_sub);
    }, function(){
    $('.fb-gb-sticky-nav-2').css("background-color", "");
});

$(".fb-xxx-3").hover(function(){
    $('.fb-gb-sticky-nav-3').css("background-color", underline_sub);
    }, function(){
    $('.fb-gb-sticky-nav-3').css("background-color", "");
});

// ====================================
// Extention Panel
// ====================================
$(function(){
    $('.fb-extentionPanel').children("button").on( "click", function(){
        $(this).next('.fb-area').slideToggle("fast");

        $otherAreas = $(".fb-area").not($(this).next(".fb-area"));
        $otherAreas.prev("button").removeClass('active');
        $otherAreas.slideUp("fast");

        $(this).toggleClass("active");
    });
});

// ====================================
// Animation
// ====================================
/* Berechnet, wann ein Element sichtbar ist */
$.fn.inView = function (inViewType) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var viewport = {};
  viewport.top = $(window).scrollTop();
  viewport.bottom = viewport.top + $(window).height();

  var bounds = {};
  bounds.top = this.offset().top + offset;
  bounds.bottom = bounds.top + this.outerHeight();

  switch (inViewType) {
    case 'bottomOnly':
      return bounds.bottom <= viewport.bottom && bounds.bottom >= viewport.top;

    case 'topOnly':
      return bounds.top <= viewport.bottom && bounds.top >= viewport.top;

    case 'both':
      return bounds.top >= viewport.top && bounds.bottom <= viewport.bottom;

    default:
      return bounds.top >= viewport.top && bounds.bottom <= viewport.bottom;
  }
};

function checkVisability_to3() {
    if ($('.fb-animation-to3').inView("topOnly")) {
        $('.fb-animation-to3-1').addClass("animate__animated animate__fadeInLeftBig");
        $('.fb-animation-to3-2').addClass("animate__animated animate__fadeInRightBig");
    } else {
        $('.fb-animation-to3-1').removeClass("animate__animated animate__fadeInLeftBig");
        $('.fb-animation-to3-2').removeClass("animate__animated animate__fadeInRightBig");
    }
}

function checkVisability_ownSound() {
    if ($('.fb-animation-ownSound-1').inView("topOnly")) {
        $('.fb-animation-ownSound-1').addClass("animate__animated animate__flipInX");
    } else {
        $('.fb-animation-ownSound-1').removeClass("animate__animated animate__flipInX");
    }

    if ($('.fb-animation-ownSound-2').inView("topOnly")) {
        $('.fb-animation-ownSound-2').addClass("animate__animated animate__flipInY");
    } else {
        $('.fb-animation-ownSound-2').removeClass("animate__animated animate__flipInY");
    }

    if ($('.fb-animation-ownSound-3').inView("topOnly")) {
        $('.fb-animation-ownSound-3').addClass("animate__animated animate__flipInY");
    } else {
        $('.fb-animation-ownSound-3').removeClass("animate__animated animate__flipInY");
    }
}
