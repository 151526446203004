function loadColumns(column){
    var database_name = document.getElementById('db_name_id');
    var select = document.getElementById('column_names_id');
    axios.post('post.php' , {
        action: 'loadcolumns',
        ColumnValvue: column.value,
        dbname: database_name.value 
    }).then((response) => {
        spalten_name = response.data;
        var x = select.options.length;
        for(var i = 0; i < x; i++){
            select.remove(0);
        }
        for(var i = 0; i < spalten_name.length; i++){
            var opt = document.createElement('option');
            opt.value = spalten_name[i]['COLUMN_NAME'];
            opt.innerHTML = spalten_name[i]['COLUMN_NAME'];
            select.appendChild(opt);
        }
    }); 
}

function loadOrDelite(){
    var checkbox_delete = document.getElementById('checkbox_delete_id');
    var load_button = document.getElementById('load_button_id');

    if(checkbox_delete.value == "No"){ 
        checkbox_delete.value = "Yes";
        load_button.classList = 'input-group-text btn btn-danger';
        load_button.value = 'Löschen';
    }else{
        checkbox_delete.value = "No";
        load_button.classList = 'input-group-text btn btn-success';
        load_button.value = 'Laden';
    }
}

function OnOffCheckBox(){
    var checkbox_delete = document.getElementById('checkbox_delete_id');
    var load_button = document.getElementById('load_button_id');
    var select_abfrage = document.getElementById('select_abfrage_id');

    if(select_abfrage.value == "0"){
        checkbox_delete.disabled = true;
        checkbox_delete.checked = false;
        load_button.classList = 'input-group-text btn btn-success';
        load_button.value = 'Laden';
        checkbox_delete.value = "No";
    }else{
        checkbox_delete.disabled = false;
        checkbox_delete.checked = false;
        load_button.classList = 'input-group-text btn btn-success';
        load_button.value = 'Laden';
        checkbox_delete.value = "No";
    }
}

function loadOrSave(){
    var speichern_als = document.getElementById('speichern_als_id');
    var submit_senden = document.getElementById('submit_senden_id');

    if(speichern_als.value == ''){
        submit_senden.classList = 'btn btn-primary';
        submit_senden.value = 'Senden';
    }else{
        submit_senden.classList = 'btn btn-success';
        submit_senden.value = 'Speichern';
    }
}

function forbiddenKeys(){
    var tarea = document.getElementById('textArea_select_id');
    var keys = ['ALTER', 'DROP'];
    var findKey = '';
    var upperCaseString = '';

    upperCaseString = tarea.value.toUpperCase();

    for(let i = 0; i < keys.length; i++){
        findKey = upperCaseString.search(keys[i]);
        if(findKey >= 0){
            alert('Die Schlüsselwörter ALTER und DROP sind aus Sicherheitsgründen gesperrt.');
            tarea.value = '';
        }
    }
}

/* =========================
    Button functions
========================= */
function btn_select_all(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    var selected_table_name = document.getElementById('table_names_id');
    div_select.innerText = `SELECT * FROM ` + selected_table_name.value + ` WHERE 1`;
    tarea.value = div_select.innerText;
    changeColor();
}

function btn_select(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    var selected_table_name = document.getElementById('table_names_id');
    var column_names = document.getElementById('column_names_id').options;
    var spalten = '';

    for(var i = 0; i < column_names.length; i++){
        if(i < column_names.length - 1){
            spalten += column_names[i].value + ", ";
        }else{
            spalten += column_names[i].value + " ";
        }
    }
    
    div_select.innerText = "SELECT " + spalten + "FROM "  + selected_table_name.value + " WHERE 1";
    tarea.value = div_select.innerText;
    changeColor();
}

function btn_insert(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    var selected_table_name = document.getElementById('table_names_id');
    var column_names = document.getElementById('column_names_id').options;
    var spalten = '';
    var values = '';
    var j = 1;

    for(var i = 0; i < column_names.length; i++){
        if(i < column_names.length - 1){
            spalten += column_names[i].value + ", ";
            values += "\"[value-" + j + "]\", ";
            j++;
        }else{
            spalten += column_names[i].value + " ";
            values += "\"[value-" + j + "]\"";
            j++;
        }
    }

    div_select.innerText = "INSERT INTO " + selected_table_name.value + " ("+ spalten + ") VALUES (" + values + ")";
    tarea.value = div_select.innerText;
    changeColor();
}

function btn_update(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    var selected_table_name = document.getElementById('table_names_id');
    var column_names = document.getElementById('column_names_id').options;
    var spalten = '';

    var j = 1;
    for(var i = 0; i < column_names.length; i++){
        if(i < column_names.length - 1){
            spalten += column_names[i].value + " = [value-" + (j) + "], ";
        }else{
            spalten += column_names[i].value + " = [value-" + (j) + "] ";
        }
        j++;
    }

    div_select.innerText = "UPDATE " + selected_table_name.value + " SET " + spalten + " WHERE 1";
    tarea.value = div_select.innerText;
    changeColor();
}

function btn_delete(){
    var tarea = document.getElementById('textArea_select_id');
    var tarea_2 = document.getElementById('textArea_2_select_id');
    var div_select = document.getElementById('div_select_id');
    
    // Wegen Sicherheitsvorkehrung gesperrt
    /* var selected_table_name = document.getElementById('table_names_id');
    div_select.innerText = "DELETE FROM " + selected_table_name.value + " WHERE 0";
    tarea.value = div_select.innerText; */

    alert('Aus Sicherheitsgründen ist diese Funktion gesperrt.')
    div_select.innerText = tarea.value = tarea_2.value = "";
    changeColor();
}

/* ========================= 
    Von der div zur textarea
========================= */
function fromDivToTextarea(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    forbiddenKeys_2(div_select);
    tarea.value = div_select.innerText;
    console.log('marked(tarea.value)');
}

function fromDivToTextarea_2(){
    var tarea_2 = document.getElementById('textArea_2_select_id');
    var div_select = document.getElementById('div_select_id');
    tarea_2.value = div_select.innerHTML;
}

/* =========================
    SQL-Syntax Manipulation - SICHERHEIT
========================= */
function forbiddenKeys_2(tarea){
    var keys = ['ALTER', 'DROP', 'DELETE'];
    var findKey = '';
    var upperCaseString = '';
    upperCaseString = tarea.innerHTML.toUpperCase();

    for(let i = 0; i < keys.length; i++){
        findKey = upperCaseString.search(keys[i]);
        if(findKey >= 0){
            alert('Die Schlüsselwörter ALTER, DROP und DELETE sind aus Sicherheitsgründen gesperrt.');
            tarea.innerHTML = '';
        }
    }
}

/* =========================
    COLORRATE
========================= */
function changeColor(){
    var tarea = document.getElementById('textArea_select_id');
    var div_select = document.getElementById('div_select_id');
    var keywords_1 = ["WHERE", "SELECT", "UPDATE", "INSERT", "SET", "FROM", "AND", "AS", "INTO", "SET", "DELETE", "ALTER", "TABLE", "DROP", "LIMIT", "ORDER", "BY", "COUNT", "LIKE", "BETWEEN", "INNER", "JOIN", "LEFT", "RIGHT", "CROSS", "UNION", "EXISTS", "HAVING", "ANY", "ALL", "VALUES"];
    var keywords_2 = ["*"];

    for(let i = 0; i < keywords_1.length; i++){
        div_select.innerHTML = div_select.innerHTML.split(keywords_1[i]).join("<span></span><span style='color: #820e78'>" + keywords_1[i] + "</span>");
    }

    for(let i = 0; i < keywords_2.length; i++){
        div_select.innerHTML = div_select.innerHTML.split(keywords_2[i]).join("<span style='color: #ef86c7'>" + keywords_2[i] + "</span>");
    }
    
    fromDivToTextarea_2();
}
